import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import GDPRFAQ from '../components/slides/gdpr/GDPRFAQ';

const Gdpr = () => {
  return (
    <Layout>
      <SEO
        title="GDPR | Deadline Funnel"
        description="A resource for Deadline Funnel and GDPR."
      />
      <GDPRFAQ />
    </Layout>
  )
};

export default Gdpr;